import {defineStore} from 'pinia'
import {useTheme} from "vuetify";

export const useInterfaceStore = defineStore('interface', () => {
    const theme = useTheme()
    const {user} = useAuth()

    const openedDrawer: Ref<boolean|null> = ref(null)

    const darkTheme = computed({
        get() {
            return theme.global.name.value === 'dark'
        },
        set(newValue: boolean) {
            // Змінюємо тему
            theme.global.name.value = newValue ? 'dark' : 'light'

            // Оновлюємо на рівні сесії
            localStorage.setItem('blackTheme', newValue ? '1' : '0')

            // Оновлюємо в профілі без очікування результату,
            // щоб не блокувати перемикач, для збереження між пристроями
            if (!user.value.impersonatedBy) {
                useApi().updateUserProfile({request: {darkTheme: newValue}}).finally()
            }
        }
    })

    if (localStorage.getItem('openedDrawer') !== null) {
        openedDrawer.value = Boolean(parseInt(localStorage.getItem('openedDrawer') || '0'))
    }

    // По виходу з системи скидається стан сховища
    const $reset = () => {
        // darkTheme.value = null
        openedDrawer.value = null
    }

    // Разово завантажуємо раніше збережене значення теми
    if (!user.value.impersonatedBy) {
        useApi().getUserProfile().then(profile => {
            // darkTheme.value = profile.darkTheme
            theme.global.name.value = profile.darkTheme ? 'dark' : 'light'
        })
    }

    watch(openedDrawer, (newValue) => {
        // Оновлюємо на рівні сесії (для того щоб значення по замовчуванню збереглось
        localStorage.setItem('openedDrawer', newValue ? '1' : '0')
    })

    return {
        darkTheme,
        openedDrawer,
        $reset
    }
})