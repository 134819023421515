import {defineStore} from 'pinia'

export const useBookingInputStore = defineStore('bookingInput', {
    state: () => ({
        zone: undefined as number | undefined,
        freqRange: undefined as string | undefined,
        freqRange2: undefined as string | undefined,
        band: undefined as string | undefined,
        band2: undefined as string | undefined,
        channel: undefined as string | undefined,
        channel2: undefined as string | undefined,
        pilot: undefined as number | undefined,
        withRepeater: undefined as boolean | undefined,
        repeaterChannel: undefined as string | undefined,
        secondChannelInUse: undefined as boolean | undefined,
    }),
    actions: {
        unload() {
            this.zone = undefined;
            this.freqRange = undefined;
            this.freqRange2 = undefined;
            this.band = undefined;
            this.band2 = undefined;
            this.channel = undefined;
            this.channel2 = undefined;
            this.pilot = undefined;
            this.withRepeater = undefined;
            this.repeaterChannel = undefined;
            this.secondChannelInUse = undefined;
        },
    }
})