import {defineStore} from 'pinia'
import type {OutputZone, OutputZoneChannelData} from "~/models";
import {state} from "sucrase/dist/types/parser/traverser/base";

export const useChannelsStore = defineStore('channels', {
    state: () => ({
        zones: [] as OutputZone[],
        channels: {} as {[keys: number]: OutputZoneChannelData},
    }),
    getters: {
        channelInfoByZone: (state: any) => {
            return (zoneId: number) => state.channels[zoneId] || null
        },
    },
    actions: {
        async load() {
            this.zones = await useApi().getFlyZones()

            const loadZoneData = async (zoneId: number) => {
                this.channels[zoneId] = await useApi().getFlyZoneChannelData({zone: zoneId})
            }

            const promises = []
            for (const zone of this.zones) {
                promises.push(loadZoneData(zone.id))
            }

            await Promise.all(promises)
        },
        unload() {
            this.zones = []
            this.channels = {}
        }
    }
})